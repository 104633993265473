import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ConnectionService } from 'src/services/connection.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  submitted: boolean;
  constructor(public formBuilder: FormBuilder, private connectionService: ConnectionService) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      txtName: ['', Validators.required],
      txtCompany: ['', Validators.required],
      txtPhone: ['', Validators.required],
      txtEmail: ['', [Validators.required, Validators.email]],
      txtBudget: [''],
      txtNeed: [''],
      txtMsg: ['', Validators.required]
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }

  fnSendMsg()  {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
        return;
      }

      // display form values on success
      // alert('Message Sent Success :) \n' + JSON.stringify(this.contactForm.value, null, 4));
    this.connectionService.sendMessage(this.contactForm.value).subscribe(() => {
        alert('Your Message Has Been Sent SuccessFully We will Get Back U Soon.');
        this.fnCancel();
      }, error => {
        console.log('Error', error);
      });
  }

  fnCancel() {
    this.submitted = false;
    this.contactForm.reset();
  }
}
