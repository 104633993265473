import { Component } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Inspirative Lancers India Pvt Ltd';

  constructor(private titleService: Title, private meta: Meta) {
    titleService.setTitle('Inspirative Lancers India Pvt Ltd');
    meta.updateTag({name: 'viewport', content: 'width=device-width, initial-scale=1.0'});
    meta.addTag({name: 'keywords', content: ''}, true);
    meta.addTag({name: 'description', content: ''}, true);
  }

}
