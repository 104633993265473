import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  todayDate =  new Date();
  date: any;
  month: any;
  year: any;

  constructor() { }

  ngOnInit() {
    this.date = this.todayDate.getDate();
    this.month = this.todayDate.getMonth();
    this.year = this.todayDate.getFullYear();
  }
}
